import {
  Badge,
  Button,
  Dropdown,
  DropdownButton,
  Form,
} from 'react-bootstrap';
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import hash from 'object-hash';

export default function ActionTable(props) {
  const {
    question,
    value: values,
    callbackFunction,
  } = props;

  const gridRef = useRef();
  const actionsRef = useRef();
  const [gridData, setGridData] = useState(values);
  const [rowBadges, setRowBadges] = useState(question.rowBadges || []);
  const [rowActions, setRowActions] = useState(question.rowActions || []);
  const [tableActions, setTableActions] = useState(question.tableActions || []);

  useEffect(() => {
    setGridData(values);
  }, [values]);

  const refreshGrid = useCallback(
    () => {
      if (!gridRef.current?.api) { return; }
      gridRef.current.api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    },
    [values],
  );

  const checkShowConditions = (conditions, rowData) => {
    if (!rowData || !conditions || conditions.length < 1) {
      return true;
    }
    let show = false;

    for (let i = 0; i < conditions.length; i += 1) {
      const condition = conditions[i];
      let a = rowData[condition.field];
      let b = condition.value;

      if (typeof a === 'string' && typeof b === 'string') {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (a === b) {
        show = true;
      }
    }

    return show;
  };

  const getBadge = (opts, rowData) => {
    if (!opts) {
      return null;
    }

    return (checkShowConditions(opts.conditions, rowData)
      && (
        <Badge
          size="sm"
          bg={opts.type.toLowerCase()}
          onClick={opts.callback}
          id={opts.id}
          key={hash(opts.type, opts.label)}
          style={{ marginRight: 5 }}
        >
          { opts.label ? opts.label : rowData[opts.field] }
        </Badge>
      )
    );
  };

  const getButton = (opts, rowData) => {
    if (!opts) {
      return null;
    }

    return (checkShowConditions(opts.conditions, rowData)
      && (
        <Button
          size="sm"
          variant={opts.type.toLowerCase()}
          onClick={opts.callback}
          id={opts.id}
          key={hash(opts.type, opts.label)}
          style={{ marginRight: 10 }}
        >
          {opts.label}
        </Button>
      )
    );
  };

  const generateDropdownList = (opts) => {
    if (!opts.defaultDropdownOptions) { return null; }

    // Filter out existing records in table
    let dropdownOptions = [...opts.defaultDropdownOptions];
    dropdownOptions = dropdownOptions.filter((option) => {
      const index = gridData.map((row) => row.id).indexOf(option.id);

      return index === -1;
    });

    return dropdownOptions.map((item) => {
      const badges = opts.badges?.map((badgeOpts) => getBadge(badgeOpts, item));

      return (
        <Dropdown.Item
          id={opts.id}
          key={hash(item.id, item.name)}
          onClick={(e) => {
            const message = {
              target: {
                id: e.target.id,
                name: e.target.name,
                value: item,
                type: 'BUTTON',
              },
            };
            callbackFunction(message);
          }}
        >
          {item.name}
          <div className="float-right" style={{ marginLeft: 10 }}>
            { badges }
          </div>
        </Dropdown.Item>
      );
    });
  };

  const getDropdown = (opts) => {
    if (!opts) {
      return null;
    }

    return (
      <DropdownButton
        title={opts.label ? opts.label : ''}
        variant="success"
        id={opts.id}
        key={hash(opts.type, opts.label)}
      >
        { generateDropdownList(opts) }
      </DropdownButton>
    );
  };

  const getRowElement = (opts, rowData) => {
    const callback = (e) => {
      const message = {
        target: {
          id: e.target.id,
          name: e.target.name,
          value: rowData,
          type: 'BUTTON',
          callback: refreshGrid,
        },
      };
      callbackFunction(message);
    };
    if (opts) {
      opts.callback = callback;
    }

    return {
      button: opts?.type.toLowerCase() === 'dropdown'
        ? () => getDropdown(opts)
        : () => getButton(opts, rowData),
      badge: () => getBadge(opts, rowData),
    };
  };

  const btnGroupRenderer = (prop) => (
    <div ref={actionsRef} style={{ marginRight: 10 }}>
      { rowActions.map((opts) => getRowElement(opts, prop?.data).button()) }
    </div>
  );

  const badgeRenderer = (prop) => {
    const rowData = prop?.data;
    const colField = prop?.column.colId;
    const opts = rowBadges.filter((rowBadge) => rowBadge.field === colField)
      .find((rowBadge) => checkShowConditions(rowBadge.conditions, rowData));

    return (
      <div>
        { getRowElement(opts, rowData).badge() }
      </div>
    );
  };

  /**
   * Column Defs
   */
  const defaultColDef = {
    sortable: false,
    filter: false,
    editable: false,
    suppressMovable: true,
    suppressMenu: true,
    resizable: true,
  };

  const [columnDefs, setColumnDefs] = useState(question?.columnDefs?.map((col) => {
    col = {
      headerName: col.label,
      field: col.field,
      type: col.type,
    };

    if (col.type === 'badge') {
      col.cellRenderer = badgeRenderer;
    }

    if (col.field === 'actions') {
      col.cellRenderer = btnGroupRenderer;
      col.width = rowActions.length * 120;
      col.pinned = 'right';
    } else {
      col.flex = 1;
    }

    return col;
  }));

  const columnTypes = {
    badge: {},
  };

  /**
   * Column Resizing
   */

  const fitColumns = () => {
    // Boiler Plate for future reference / expansion
  };

  const onGridReady = (params) => {
    fitColumns();
  };

  window.onresize = () => {
    fitColumns();
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">{question.label}</Form.Label>
      <div className="ag-theme-alpine" style={{ height: window.innerHeight / 2 }}>
        <AgGridReact
          ref={gridRef}
          rowData={gridData}
          columnDefs={columnDefs}
          columnTypes={columnTypes}
          defaultColDef={defaultColDef}
          suppressContextMenu="true"
          onGridReady={onGridReady}
          pagination
          paginationAutoPageSize
          enableCellChangeFlash
        />
        <div className="" style={{ margin: 10 }}>
          { tableActions.map((opts) => getRowElement(opts, null).button()) }
        </div>
      </div>
    </Form.Group>
  );
}
