import GenericUtils from 'utils/GenericUtils';
import moment from 'moment-timezone';
import DescriptionCellRenderer from 'components/Table/DescriptionCellRenderer';

export default class UsagesHelper {
  static mapYears = (arr) => {
    // Reformat usage data year = [months]
    const maping = {};
    arr[0].usage.forEach((item) => {
      if (!Object.prototype.hasOwnProperty.call(maping, item.year)) {
        maping[item.year] = [];
      }
      if (!maping[item.year].includes(item.month)) {
        maping[item.year].push(moment.months(item.month - 1));
      }
    });

    return maping;
  };

  static mapUsagesData = (data) => {
    const maps = [
      {
        field: 'name',
        width: 300,
        // eslint-disable-next-line max-len, react/react-in-jsx-scope
        cellRenderer: (params) => <DescriptionCellRenderer name={params.data?.name} description={params.data?.key} />,
        resizable: true,
        sort: 'asc',
        pinned: true,
      },
      {
        headerName: 'Status',
        field: 'active',
        headerClass: 'text-center',
        cellRenderer: (params) => (params.value ? 'Active' : 'Inactive'),
        cellStyle: (params) => GenericUtils.statusCellStyle(params),
        width: 120,
        resizable: true,
        pinned: true,
      },
      {
        field: 'average',
        headerClass: 'text-center',
        cellStyle: { textAlign: 'center' },
        width: 120,
        pinned: true,
      },
      {
        field: 'total',
        headerClass: 'text-center',
        cellStyle: { textAlign: 'center' },
        width: 100,
        pinned: true,
      },
    ];
    const maping = this.mapYears(data);
    const order = Object.keys(maping).reverse();
    order.forEach((item) => {
      maping[item].forEach((m) => {
        maps.push({
          field: `${m}-${item}`,
          headerName: `${GenericUtils.capitalizeFirstLetter(m).slice(0, 3)} ${item.slice(2)}`,
          headerClass: 'text-center',
          cellStyle: { textAlign: 'center' },
          width: 120,
        });
      });
    });

    return maps;
  };

  static reformatUsageData = (usages) => {
    // Reformat Row data
    const newUsages = [];
    let reformat = {};
    usages.forEach((item) => {
      reformat.id = item.id;
      reformat.name = item.name;
      reformat.key = item.key;
      reformat.active = item.active;
      reformat.average = Number(item.average);
      reformat.total = Number(item.total);
      item.usage.forEach((us) => {
        reformat[`${moment.months(us.month - 1)}-${us.year}`] = us.active_patients;
      });
      newUsages.push(JSON.parse(JSON.stringify(reformat)));
      reformat = {};
    });

    return newUsages;
  };

  static setButtomPinnedRowData = (data) => {
    // Set Pinned row data
    const res = [];
    const total = {
      name: 'Total',
    };
    res.push(this.mapRowAndSetUsageTotal(data, total));

    return res;
  };

  static mapRowAndSetUsageTotal = (data, obj) => {
    // Used in setButtomPinnedRowData
    const total = obj;
    data.forEach((item) => {
      Object.keys(item).forEach((m) => {
        if (m !== 'name') {
          if (Object.prototype.hasOwnProperty.call(total, m)) {
            total[m] += item[m];
          } else {
            total[m] = item[m];
          }
        }
      });
    });
    delete total.key;
    delete total.average;

    return total;
  };

  static setMonthsUsage = (() => {
    let n = 1;
    const options = [];
    while (n !== 13) {
      const obj = {};
      obj.text = `Last ${n} Full Months...`;
      obj.id = n;
      obj.value = n;
      options.push(obj);
      n += 1;
    }

    return options;
  })();
}
