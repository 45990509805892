/**
 * @class FormValidation
 * @description Set
 */
export default class FormValidation {
  static validateEmail = (email) => {
    const re = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    return re.test(String(email).toLowerCase());
  };

  static validateEmailMulti = (commaSeparatedEmails) => {
    const emails = commaSeparatedEmails.split(',').map((email) => email.trim());

    return emails.every(FormValidation.validateEmail);
  };

  static validateCIDR = (cidr) => {
    const ipCidrPattern = /^(\d{1,3}\.){3}\d{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

    if (!ipCidrPattern.test(cidr)) {
      return false;
    }

    const parts = cidr.split('/');
    const ipParts = parts[0].split('.');
    const invalidPart = ipParts.find((part) => parseInt(part, 10) > 255);

    if (invalidPart) {
      return false;
    }

    if (parts[1] && parseInt(parts[1], 10) > 32) {
      return false;
    }

    return true;
  };

  static validateInput = (value, validationType, isRequired) => {
    if (!value && isRequired) {
      return false;
    }

    if (!value) {
      return true;
    }

    const validationFunction = FormValidation.validationFunctions[validationType];

    return validationFunction ? validationFunction(value) : true;
  };

  static validationFunctions = {
    EMAIL_MULTI: FormValidation.validateEmailMulti,
    CIDR: FormValidation.validateCIDR,
    // Add additional validation types here, e.g.:
    // SSN: FormValidation.validateSSN,
  };

  // Other validation helper functions

  static withOutSimbolsRegex = /.*[!@#$%^&*,/\\\\()¿~_'<>:;+.=?-].*/;

  static onlyNumbersRegex = /^-?\d+\.?\d*$/;

  static positiveNegativeNumbers = '[^0-9\\.-]|(?<=.)-|\\.(?=.*\\.)';

  /**
   * @description Validate regex
   * @param {*} target String
   * @returns Boolean
   */
  static validateIsql = (target) => target.match(this.withOutSimbolsRegex);

  static onlyNumbers = (target) => target.match(this.onlyNumbersRegex);

  static positiveNegativeNumsValidate = (target) => target.match(this.onlyNumbersRegex);

  /**
   * @description Set basic style when validate is required
   * @param {*} type String
   * @param {*} validate Boolean
   * @returns Object
   */
  static validateStyleColor = (type, validate) => {
    if (type.length > 0 && validate) {
      return { color: 'var(--color-active)', background: 'var(--color-active-bg)' };
    }
    if (!validate) {
      return { color: 'var(--color-deleted)', background: 'var(--color-deleted-bg)' };
    }

    return {};
  };

  static styleSendRequestValidateCode = (type, factor) => {
    if (type && !factor) {
      return 'outline-primary';
    }
    if (factor && type) {
      return 'outline-success';
    }

    return 'outline-secondary';
  };
}
