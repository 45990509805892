import FetchService from 'services/FetchService';

export default class AdvancedConfigurationService {
  static getRules(clientId) {
    return FetchService.fetchData('GET', `settings/rules?client_id=${clientId}`);
  }

  static createRule(clientId, rule) {
    return FetchService.fetchData('POST', `settings/rules?client_id=${clientId}`, { rule });
  }

  static pathClientRules(clientId, rules) {
    return FetchService.fetchData('PATCH', `settings/rules?client_id=${clientId}`, { rules });
  }

  static updateRule(clientId, ruleId, rule) {
    return FetchService.fetchData('PATCH', `settings/rules/${ruleId}?client_id=${clientId}`, { rule });
  }
}
